<template>
  <div>
    <div v-if="loading.pack" class="d-flex justify-center">
      <div class="text-center mt-5">
        <v-progress-circular
          indeterminate
          color="secondary"
          width="4"
          size="60"
        ></v-progress-circular>
      </div>
    </div>
    <div v-else>
      <div v-if="pack" class="d-flex align-center justify-center">
        <div style="width: 550px">
          <div class="text-h5 text-center font-weight-medium mt-8">
            {{ $t("pack.buy.show.title", { title: pack.title }) }}
          </div>
          <div class="grey--text text-center text--darken-2 mb-8">
            {{ $t("pack.buy.show.description") }}
          </div>
          <v-form
            class="payment-form"
            @submit.prevent="buy()"
            autocomplete="off"
          >
            <v-card
              elevation="1"
              max-width="550"
              class="pb-2"
              :style="`border-top: 5px solid ${pack.color}; border-bottom: 5px solid ${pack.color}`"
            >
              <v-card-text class="px-5 mb-0 pb-0">
                <h2 class="mb-0 text-h5 font-weight-medium">
                  {{ pack.title }}
                </h2>
                <div class="text-body-1 mt-0 grey--text text--darken-2">
                  <div>{{ pack.application.title }}</div>
                  <div>{{ pack.description }}</div>
                </div>
              </v-card-text>
              <v-card-text class="px-5 mt-2">
                <div class="font-weight-bold mt-1 mb-2 text-subtitle-2">
                  <div class="mb-1">
                    {{ $t("payment.fields.quantity.title1") }}
                  </div>
                  <div>
                    {{ formatQuantity(pack.quantity) }} /
                    {{ pack.unit.toUpperCase() }}
                  </div>
                </div>
                <v-text-field
                  :placeholder="
                    $t('payment.fields.quantity.title2', {
                      unit: pack.unit.toUpperCase(),
                    })
                  "
                  append-icon="mdi-counter"
                  v-model="form.quantity"
                  dense
                  outlined
                  type="number"
                  :error-messages="quantityErrors"
                  @input="delayTouch($v.form.quantity)"
                  @blur="$v.form.quantity.$touch"
                  autocomplete="off"
                ></v-text-field>
                <div class="font-weight-bold mt-1 mb-2 text-subtitle-2">
                  {{ $t("payment.fields.accountType.title1") }}
                </div>
                <v-select
                  outlined
                  dense
                  append-icon="mdi-bank-outline"
                  :placeholder="$t('payment.fields.accountType.title2')"
                  :items="operators"
                  v-model="form.accountType"
                  :error-messages="accountTypeErrors"
                  @input="delayTouch($v.form.accountType)"
                  @blur="$v.form.accountType.$touch"
                  class="operator"
                  :item-value="(item) => item.code"
                >
                  <template v-slot:selection="data">
                    <v-avatar size="30" class="mb-2 mt-2">
                      <img :src="data.item.avatar" />
                    </v-avatar>
                    <div class="ml-2 my-auto">
                      <span class="font-weight-bold text-body-1">{{
                        data.item.title
                      }}</span>
                    </div>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <img :src="data.item.avatar" width="40" />
                    </v-list-item-avatar>
                    <v-list-item-content class="ml-2">
                      <v-list-item-title>
                        <span class="font-weight-bold text-body-1">{{
                          data.item.title
                        }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
                <template v-if="form.accountType !== 'PAYPAL'">
                  <div class="font-weight-bold mt-1 text-subtitle-2">
                    {{ $t("payment.fields.accountNumber.title1") }}
                  </div>
                  <v-text-field
                    :placeholder="$t('payment.fields.accountNumber.title2')"
                    append-icon="mdi-cellphone"
                    v-model="form.accountNumber"
                    dense
                    outlined
                    :error-messages="accountNumberErrors"
                    @input="delayTouch($v.form.accountNumber)"
                    @blur="$v.form.accountNumber.$touch"
                  ></v-text-field>
                  <template v-if="form.accountType === 'OM'">
                    <div class="font-weight-bold mt-1 mb-1 text-subtitle-2">
                      {{ $t("payment.fields.otp.title1") }}
                    </div>
                    <div
                      class="
                        font-weight-bold
                        mb-2
                        text-subtitle-2
                        primary--text
                      "
                    >
                      {{ $t("payment.fields.otp.help") }}
                    </div>
                    <v-text-field
                      :placeholder="$t('payment.fields.otp.title2')"
                      append-icon="mdi-cellphone-key"
                      v-model="form.otp"
                      dense
                      outlined
                      :error-messages="otpErrors"
                      @input="delayTouch($v.form.otp)"
                      @blur="$v.form.otp.$touch"
                      maxlength="6"
                    ></v-text-field>
                  </template>
                </template>
                <div v-if="total" class="text-h6 mt-2 mb-0 font-weight-bold">
                  <span class="mr-2"
                    >{{ $t("payment.fields.total.title") }}:</span
                  >
                  <span class="primary--text">{{ total }}</span>
                  <span v-if="totalWithCurrencyRate" class="primary--text"
                    ><span class="mx-1">|</span
                    >{{ totalWithCurrencyRate }}</span
                  >
                </div>
              </v-card-text>
              <v-card-actions class="px-5 mt-0 pt-0">
                <v-btn
                  :loading="loading.buy"
                  :disabled="$v.form.$invalid"
                  color="primary"
                  block
                  type="submit"
                  v-if="form.accountType !== 'PAYPAL'"
                >
                  <span>{{ $t("btn.validate") }}</span>
                </v-btn>
                <template v-else>
                  <paypal-button
                    :create-order="createOrderPaypal"
                    :on-approve="onApproveOrderPaypal"
                    :on-pre-submit="onPreSubmitOrderPaypal"
                    v-show="!loading.paypal"
                  ></paypal-button>
                  <div
                    class="text-center"
                    v-show="loading.paypal"
                    style="width: 100%"
                  >
                    <v-progress-circular
                      indeterminate
                      color="secondary"
                      width="3"
                      size="30"
                      v-show="loading.paypal"
                    ></v-progress-circular>
                  </div>
                </template>
              </v-card-actions>
            </v-card>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import { required, requiredIf, helpers } from "vuelidate/lib/validators";
import PackFormatterMixin from "./../../mixins/packs/formatter";
import { mapActions, mapGetters } from "vuex";
import PaypalButton from "./../commons/paypal-button";

export default {
  mixins: [PackFormatterMixin, FormDelayTouchMixin],
  props: {
    operators: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  created() {
    this.setPack();
    this.title = this.$t("pack.buy.title");
  },
  data: () => ({
    pack: null,
    loading: {
      pack: false,
      buy: false,
      paypal: false,
    },
    title: null,
    form: {
      accountType: "OM",
      accountNumber: null,
      quantity: null,
      otp: null,
    },
  }),
  validations() {
    return {
      form: {
        accountType: {
          required,
          accountType: (value) => {
            if (!helpers.req(value)) return true;
            return (
              this.operators.findIndex((operator) => operator.code === value) >
              -1
            );
          },
        },
        accountNumber: {
          required: requiredIf(function () {
            return this.form.accountType !== "PAYPAL";
          }),
          accountNumber: (value) =>
            !helpers.req(value) ||
            this.$utils.validateAccountNumber(value, this.form.accountType),
        },
        quantity: {
          required,
          quantity: (value) =>
            !helpers.req(value) ||
            this.$utils.validateQuantityPayment(value, this.pack.quantity),
        },
        otp: {
          required: requiredIf(function () {
            return this.form.accountType === "OM";
          }),
          otp: helpers.regex("otp", /^[0-9]{6}$/),
        },
      },
    };
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
  methods: {
    async setPack() {
      this.loading.pack = true;
      const id = this.$route.params.id;
      if (id) {
        try {
          const response = await this.request({
            url: `/api/packs?filter=id||$eq||${id}`,
          });
          const pack = this.$objectPath.get(response.data, "data.0", {});
          if (pack.id === parseInt(id)) {
            this.title = this.$t("pack.buy.show.title", {
              title: pack.title,
            });
            this.pack = pack;
          } else {
            const message = this.$t("pack.errors.not_found");
            this.title = message;
            this.notify({ message: message });
          }
        } catch (error) {
          // empty
        }
      }
      this.loading.pack = false;
    },
    async buy() {
      this.notify({ status: false });

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({ message: this.$t("error_codes.bad_request") });
        return;
      }
      this.loading.buy = true;
      try {
        this.request({
          url: "/api/payments",
          method: "post",
          data: {
            ...this.form,
            pack: this.pack.id,
            quantity: parseInt(this.form.quantity),
          },
          messages: {
            500: true,
            400: true,
            404: this.$t("pack.errors.not_found"),
            422: (error) => {
              let message = "error_codes.error_occured";
              let params = {};
              const code = error.code;
              switch (code) {
                case "E100":
                case "E101":
                  message = "pack.errors.not_found";
                  break;
                case "E102":
                  message = "payment.field.quantity.invalid";
                  break;
                case "E103":
                  message = "user.errors.application_not_activated";
                  params = { title: this.pack.title };
                  break;
                case "E104":
                  message = "payment.field.accountNumber.invalid";
                  break;
                case "E105":
                  message = "payment.field.accountType.deactivated";
                  break;
              }
              return this.$t(message, params);
            },
          },
        });
        // notification
        const operator = this.operators.find(
          (op) => this.form.accountType === op.code
        );
        if (operator && operator.help) {
          this.notify({
            type: "large",
            avatar: operator.avatar,
            message: operator.help,
            title: this.$t("payment.help.title"),
            timeout: 60000,
          });
        } else {
          this.notify({ message: this.$t("payment.new.success") });
        }
        // redirect to payment page
        this.$router.push(this.$i18n.getRouteLink("payment"));
      } catch (error) {
        //empty
      }
      this.loading.buy = false;
    },
    async onPreSubmitOrderPaypal(data, actions) {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({ message: this.$t("error_codes.bad_request") });
        return actions.reject();
      } else {
        return actions.resolve();
      }
    },
    async createOrderPaypal() {
      try {
        const response = await this.request({
          url: "/api/payments/paypal/init",
          method: "post",
          data: {
            pack: this.pack.id,
            quantity: parseInt(this.form.quantity),
          },
          messages: {
            500: true,
            400: true,
            404: this.$t("pack.errors.not_found"),
            422: (error) => {
              let message = "error_codes.error_occured";
              let params = {};
              const code = error.code;
              switch (code) {
                case "E100":
                case "E101":
                  message = "pack.errors.not_found";
                  break;
                case "E102":
                  message = "payment.field.quantity.invalid";
                  break;
                case "E103":
                  message = "user.errors.application_not_activated";
                  params = { title: this.pack.title };
                  break;
                case "E104":
                  message = "payment.errors.error_occured";
              }
              return this.$t(message, params);
            },
          },
        });
        return response.data.orderID;
      } catch (error) {
        // emtpy
      }
    },
    async onApproveOrderPaypal(data, actions) {
      this.loading.paypal = true;
      try {
        const response = await this.request({
          url: "/api/payments/paypal/capture",
          method: "post",
          data: {
            orderID: data.orderID,
          },
          messages: {
            200: (data) => {
              if (data.status === "SUCCESS") {
                return this.$t("payment.success");
              } else if (data.status === "CANCEL") {
                return this.$t("payment.cancel");
              }
            },
            500: true,
            400: true,
            422: (error) => {
              let errorData;
              let errorDetail;
              let message = "error_codes.error_occured";
              let params = {};
              const code = error.code;
              switch (code) {
                case "E100":
                case "E101":
                  message = "payment.errors.order_not_found";
                  break;
                case "E102":
                  errorData = error.data;
                  errorDetail =
                    Array.isArray(errorData.details) && errorData.details[0];
                  if (
                    errorDetail &&
                    errorDetail.issue === "INSTRUMENT_DECLINED"
                  ) {
                    return actions.restart();
                  }
                  break;
              }
              return this.$t(message, params);
            },
          },
        });
        const content = response.data;

        // redirect to payment page
        if (content.status === "SUCCESS") {
          this.$router.push(this.$i18n.getRouteLink("payment"));
        }
      } catch (error) {
        // emtpy
        console.log(error);
      }
      this.loading.paypal = false;
    },
    ...mapActions({ notify: "notification/notify", request: "request" }),
  },
  computed: {
    ...mapGetters({ currencyRate: "currencyRate" }),
    price() {
      if (!this.$v.form.quantity.$invalid && this.pack) {
        return parseInt(this.pack.price) * parseInt(this.form.quantity);
      }
      return null;
    },
    total() {
      if (this.price) {
        return this.$utils.numberFormat(
          this.price,
          this.$utils.setLocaleBCP47(this.$i18n.locale)
        );
      }
      return null;
    },
    totalWithCurrencyRate() {
      if (this.price && this.form.accountType === "PAYPAL") {
        const price = parseFloat(this.price / this.currencyRate.rateXAF);
        return this.$utils.numberFormat(
          price,
          this.$utils.setLocaleBCP47(this.$i18n.locale),
          2,
          "currency",
          this.currencyRate.currency
        );
      }
      return null;
    },
    accountTypeErrors() {
      const errors = [];

      if (!this.$v.form.accountType.$dirty) return errors;

      this.$objectPath.has(this.$v.form.accountType, "required") &&
        !this.$v.form.accountType.required &&
        errors.push(this.$t("payment.fields.accountType.required"));
      !this.$v.form.accountType.accountType &&
        errors.push(this.$t("payment.fields.accountType.invalid"));

      return errors;
    },
    accountNumberErrors() {
      const errors = [];

      if (!this.$v.form.accountNumber.$dirty) return errors;
      if (this.$objectPath.has(this.$v.form.accountNumber, "required")) {
        !this.$v.form.accountNumber.required &&
          errors.push(this.$t("payment.fields.accountNumber.required"));
      }

      !this.$v.form.accountNumber.accountNumber &&
        errors.push(this.$t("payment.fields.accountNumber.invalid"));

      return errors;
    },
    quantityErrors() {
      const errors = [];

      if (!this.$v.form.quantity.$dirty) return errors;

      !this.$v.form.quantity.required &&
        errors.push(
          this.$t("payment.fields.quantity.required", {
            unit: this.pack.unit.toUpperCase(),
          })
        );
      !this.$v.form.quantity.quantity &&
        errors.push(
          this.$t("payment.fields.quantity.invalid", {
            unit: this.pack.unit.toUpperCase(),
          })
        );

      return errors;
    },
    otpErrors() {
      const errors = [];

      if (!this.$v.form.otp.$dirty) return errors;

      if (this.$objectPath.has(this.$v.form.otp, "required")) {
        !this.$v.form.otp.required &&
          errors.push(this.$t("payment.fields.otp.required"));
      }

      !this.$v.form.otp.otp &&
        errors.push(this.$t("payment.fields.otp.invalid"));

      return errors;
    },
  },
  components: {
    PaypalButton,
  },
};
</script>