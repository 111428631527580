<template>
  <div>
    <pack-show v-if="page === 'pack'" :operators="operators"></pack-show>
    <div class="text-center mt-5" v-else-if="page === 'loading'">
      <v-progress-circular
        indeterminate
        color="secondary"
        width="4"
        size="60"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
const paypalClientId = process.env.VUE_APP_PAYPAL_CLIENT_ID;

import { mapActions, mapGetters } from "vuex";
import PackShow from "./../../components/packs/show";
export default {
  beforeRouteEnter(to, form, next) {
    next(async (vm) => {
      try {
        // load payment providers
        await vm.setPaymentProviders();
        vm.operators = vm.$store.getters.operators;
        // set currency rate
        await vm.setCurrencyRate();
        // try to load paypal payment script
        if (
          vm.operators.findIndex((operator) => operator.code === "PAYPAL") > 0
        ) {
          try {
            await vm.$loadScript(
              `https://www.paypal.com/sdk/js?client-id=${paypalClientId}&currency=${vm.currencyRate.currency}&disable-funding=credit`
            );
          } catch (error) {
            // remove paypal method
            const findIndex = vm.operators.findIndex(
              (operator) => operator.value === "PAYPAL"
            );
            if (findIndex > -1) {
              vm.operators.splice(findIndex, 1);
            }
          }
        }
        vm.page = "pack";
      } catch (error) {
        // empty
        vm.notify({ message: vm.$t("error_codes.error_occured") });
        vm.page = "error";
      }
    });
  },
  data: () => ({
    operators: [],
    page: "loading",
  }),
  methods: {
    ...mapActions({
      setCurrencyRate: "setCurrencyRate",
      setPaymentProviders: "setPaymentProviders",
      notify: "notification/notify",
      request: "request",
    }),
  },
  computed: {
    ...mapGetters({ currencyRate: "currencyRate" }),
  },
  components: {
    PackShow,
  },
};
</script>