export default {
  data: () => ({}),
  methods: {
    getPrice(amount) {
      const locale = this.$utils.setLocaleBCP47(this.$i18n.locale);
      const price = this.$utils.numberFormat(
        amount,
        locale,
        0,
        "currency",
        "XAF"
      );
      return price;
    },
    formatQuantity(value) {
      const quantities = value.split("-");
      return this.$t("pack.fields.quantity.title3", {
        min: quantities[0],
        max: quantities[1]
      });
    }
  }
};
