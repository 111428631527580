<template>
  <div id="paypal-button" class="paypal-button"></div>
</template>

<script>
export default {
  props: {
    onPreSubmit: {
      type: Function,
      default: () => {},
    },
    createOrder: {
      type: Function,
      default: () => {},
    },
    onApprove: {
      type: Function,
      default: () => {},
    },
    stylize: {
      type: Object,
      default: function () {
        return {
          color: "blue",
          size: "responsive",
          shape: 'rect',
          height: 38,
          label: "paypal"
        };
      },
    },
  },
  mounted() {
    this.setLoaded();
  },
  methods: {
    setLoaded() {
      const self = this;
      // eslint-disable-next-line no-undef
      paypal
        .Buttons({
          style: self.stylize,
          onClick: function(data, actions) {
            return self.onPreSubmit(data, actions);
          },
          createOrder: function (data, actions) {
            return self.createOrder(data, actions);
          },
          onApprove: function (data, actions) {
            return self.onApprove(data, actions);
          },
        })
        .render("#paypal-button");
    },
  },
};
</script>